
import Search from '../../../components/public/others/search/SearchHome';
import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import ButtonsTop from "../../../components/public/buttons/ButtonHome";
import Buttons from "../../../components/public/buttons/ButtonTransparenciaDinamicy";
import Footer from "../../../components/public/others/Footer";
import buttonsNavbar from "../../../components/jsons/jsonButtons"
import buttonsNavbarCM from "../../../components/jsons/jsonButtonsCM"
import { TipoPortal } from "../../../services/variables";
import SubTitle from '../../../components/public/others/title/SubTitle';
import SearchHomeDois from '../../../components/public/others/search/SearchHome copy';

function Transparência() {
 
  return (

    <>
    {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
    {TipoPortal == 3 ? <>
      <NavMenu/>    
      
      <ButtonsTop names={buttonsNavbar}/>
      <SearchHomeDois />
     
    </>:<>
      <NavMenuCM/>
      <ButtonsTop names={buttonsNavbarCM}/>

    </>}
      
      <div className="section-title rotes_pages">
       <h6> Início / Transparência</h6>
      </div>
      <SubTitle subTitle={'Transparência'} border={true}/>
      <div className='fundo_svg'>
      <Buttons/>
      </div>
      <Footer/>
    </>
  )
}

export default Transparência;
